
#lazy-loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
  }
  
  #lazy-loader img {
    margin-left: calc(100vw - 100%);
    margin-bottom: 30px;
  }
  
  #lazy-loader.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  .lazy-loader {
    animation: rotate 2s linear infinite;
    margin-left: calc(100vw - 100%);
    width: 50px;
    height: 50px;
  }
  
  .lazy-loader .path {
    stroke: #5d78ff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  