@import "../../assets/sass/variables";

.hero-img{
    position: relative;

    &:before{
        content: '';
        background: $primary-color;
        opacity: 0.2;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}