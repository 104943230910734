@import "../../assets/sass/variables";

.tox {
	.tox-toolbar,
  .tox-toolbar__overflow,
  .tox-toolbar__primary,
  .tox-menubar{
		background-color: $white-100 !important;
	}
}

.cs-scroll {
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar */
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Background color of the track */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Thumb color */
    border-radius: 4px; /* Round corners of the thumb */
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
  }
}

// Status pill

.status-pill{
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 2px;
  display: inline-block;
  line-height: normal;
  font-weight: 500;

  &.active{
    background-color: #3E9F5F1A;
    color: #3E9F5F;
  }

  &.archive{
    color: #D9AE16;
    background-color: #D4A7051A;
  }

  &.draft{
    color: #777;
    background-color: #e1e1e1;
  }
}

.nofound-box{
  padding: 20px 20px 20px 60px;
  position: relative;
  max-width: 50%;
  margin: 0 auto;

  .title{
    font-size: 22px;
    margin-bottom: 4px;
    text-transform: capitalize;
  }
  .sub-title{
    color: #8C8C8C;
    font-weight: normal;
    font-size: 16px;
  }

  .nofound-icon{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); 
    
    svg{
      height: 45px;
      width: 45px;
    }
  }
}