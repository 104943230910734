#wrap {
    width: 1200px;
    height: 700px;
    overflow: hidden;
  }
  
  #scaled-frame {
    width: 1300px;
    height: 700px;
    border: 0px;
  }
  
  #scaled-frame {
    zoom: 0.75;
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    transform: scale(0.75); /* Standard property */
    transform-origin: 0 0; /* Standard property */
  }
  
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    #scaled-frame {
      zoom: 1;
    }
  }